<template>
    <div id="report">
        <div v-if="report">
            <div class="report-header margin-bottom-55">
                <h1 class="title">Report: <span>{{ report.name }}</span></h1>
                <div class="buttons buttons-header" v-if="user">
                    <router-link
                        :to="{name:'Share-Report', params: {hash:report.hash}}"
                        class="btn btn-primary btn-outline"
                        target="_blank"
                        v-if="!isShare"
                    >
                        Share Link
                    </router-link>
                    <a :href="pdf" target="_blank"
                       class="btn btn-primary btn-outline">
                        Download PDF
                    </a>
                    <button class="btn btn-primary" @click.prevent="saveReport" :disabled="saved">
                        {{ saved ? 'Report Saved' : 'Save Report' }}
                    </button>
                </div>
            </div>
            <div class="margin-bottom-55">
                <div class="title-2">Report info</div>
                <div class="report-info">
                    <div class="">{{ report.name }}</div>
                    <div class="">
                        {{ report.start_date | date }} -
                        {{ report.end_date | date }}
                    </div>
                </div>
            </div>
            <div class="margin-bottom-55 three-col">
                <div class="coloured-box">
                    <div class="title">{{ totals.vehicle_count | number }}</div>
                    <div class="title-2">Vehicle Count</div>
                </div>
                <div class="coloured-box">
                    <div class="title">
                        {{ totals.impressions | number }}
                    </div>
                    <div class="title-2">Total Impressions</div>
                </div>
                <div class="coloured-box">
                    <div class="title">
                        {{ totals.dwell_time / 24 / 60 / 60 | number(2) }} sec
                    </div>
                    <div class="title-2">Attention Time</div>
                </div>
            </div>
            <div class="margin-bottom-55">
                <div>
                    <div class="card">
                        <div class="card-head">Vehicle counts & Vehicle impressions over time</div>
                        <div class="card-body" v-if="chartdata1">
                            <line-chart id="chart1"
                                        :height="400"
                                        :chartdata="chartdata1.data" :options="chartdata1.options"></line-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="margin-bottom-55">
                <div>
                    <div class="card">
                        <div class="card-head">Vehicle impressions across the average week</div>
                        <div class="card-body" v-if="chartdata2">
                            <bar-chart id="chart2"
                                       :height="400"
                                       :chartdata="chartdata2.data" :options="chartdata2.options"></bar-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="margin-bottom-55">
                <div>
                    <div class="card">
                        <div class="card-head">Vehicle impressions across the average day</div>
                        <div class="card-body" v-if="chartdata3">
                            <bar-chart id="chart3"
                                       :height="400"
                                       :chartdata="chartdata3.data" :options="chartdata3.options"></bar-chart>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment';
import api from "../../utils/api";
import LineChart from '../../components/Charts/LineChart';
import BarChart from '../../components/Charts/BarChart';
import {api_url} from "../../utils/vars";

export default {
    components: {LineChart, BarChart},
    data() {
        return {
            saving: false,
            report: null,
            saved: false,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            api.get('/api/reports/' + this.$route.params.hash).then(response => {
                this.report = response.data.report;
                this.saved = response.data.saved;
            })
        },
        saveReport() {
            this.saved = true;
            api.post('/api/filters/' + this.report.hash, {});
        }
    },
    filters: {
        date(value) {
            if (!value) return;
            return moment(value).format('DD/MM/YY')
        },
        number(value, num = 0) {
            if (!value) return;
            return parseFloat(value).toLocaleString('en', {
                maximumFractionDigits: num,
                minimumFractionDigits: num
            });
        },
    },
    computed: {
        user() {
            return this.$store.state.user.user;
        },
        isShare() {
            return this.$route.path.indexOf('/share') === 0;
        },
        pdf() {
            if (!this.report) return '';
            let url = api_url;
            url += '/report/' + this.report.hash + '/print'
            return url;
        },
        totals() {
            let vehicle_count = 0;
            //let impressions = 0;
            let dwell_time = 0;
            if (this.report.result && this.report.result.vehicles) {
                const vehicles = this.report.result.vehicles;
                vehicles.forEach(item => {
                    vehicle_count += item.vehicle_count;
                    //impressions += item.impressions;
                    dwell_time += item.dwell_time;
                })
            }
            return {
                vehicle_count: vehicle_count,
                impressions: vehicle_count * 1.6,
                dwell_time: dwell_time,
            }
        },
        chartdata1() {
            if (!this.report.result) return;
            if (!this.report.result.vehicles) return;
            const vehicles = this.report.result.vehicles;
            return {
                data: {
                    labels: vehicles.map(item => new Date(item.period_start)),
                    datasets: [
                        {
                            label: 'Vehicle Counts',
                            data: vehicles.map(item => Math.ceil(item.vehicle_count)),
                            backgroundColor: '#373a4b99',
                        },
                        {
                            label: 'Vehicle Impressions',
                            data: vehicles.map(item => Math.ceil(item.vehicle_count * 1.6)),
                            backgroundColor: '#d48a0299',
                        },
                    ]
                },
                options: {
                    "responsive": true,
                    maintainAspectRatio: false,
                    "scales": {
                        xAxes: [{
                            "type": "time",
                            time: {
                                tooltipFormat: 'MMM Do YYYY, h:mm a',
                                unit: 'day',
                                unitStepSize: 1,
                                displayFormats: {
                                    'day': 'MMM DD'
                                }
                            },
                            "display": true,
                            "position": "bottom",
                        }],

                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return value.toLocaleString();
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }
                                label += tooltipItem.yLabel.toLocaleString()
                                return label;
                            }
                        }
                    }
                }
            }
        },
        chartdata2() {
            if (!this.report.result) return;
            if (!this.report.result.vehicles) return;
            const vehicles = this.report.result.vehicles;

            let data = [];
            for (let i = 0; i <= 6; i++) {
                data.push({
                    days: [],
                    vehicle_count: 0,
                    impressions: 0,
                });
            }
            vehicles.forEach(item => {
                let date = new Date(item.period_start);
                let day = date.getDay() - 1;
                if (day < 0) day = 6;
                let d = 'date' + date.getFullYear() + date.getMonth() + date.getDate();
                if (data[day].days.indexOf(d) < 0) {
                    data[day].days.push(d);
                }
                data[day].vehicle_count += item.vehicle_count;
                data[day].impressions += item.impressions;
            });


            return {
                data: {
                    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',],
                    datasets: [
                        {
                            label: 'Vehicle Counts',
                            data: data.map(item => Math.ceil(item.vehicle_count / item.days.length)),
                            backgroundColor: '#373a4b',
                        },
                        {
                            label: 'Vehicle Impressions',
                            data: data.map(item => Math.ceil(item.vehicle_count * 1.6 / item.days.length)),
                            backgroundColor: '#d48a02',
                        },
                    ]
                },
                options: {
                    "responsive": true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return value.toLocaleString();
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }
                                label += tooltipItem.yLabel.toLocaleString()
                                return label;
                            }
                        }
                    }
                }
            }
        },
        chartdata3() {
            if (!this.report.result) return;
            if (!this.report.result.vehicles) return;
            const vehicles = this.report.result.vehicles;

            let data = [];
            let labels = [];
            for (let i = 0; i <= 23; i++) {
                let label = i;
                if (label >= 12) label -= 12;
                if (label == 0) label = 12;
                label += i >= 12 ? 'pm' : 'am';
                labels.push(label);
                data.push({
                    days: [],
                    vehicle_count: 0,
                    impressions: 0,
                });
            }
            vehicles.forEach(item => {
                let date = new Date(item.period_start);
                let day = date.getHours();
                let d = 'date' + date.getFullYear() + date.getMonth() + date.getDate() + date.getHours();
                if (data[day].days.indexOf(d) < 0) {
                    data[day].days.push(d);
                }
                data[day].vehicle_count += item.vehicle_count;
                data[day].impressions += item.impressions;
            });


            return {
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Vehicle Counts',
                            data: data.map(item => Math.ceil(item.vehicle_count / item.days.length)),
                            backgroundColor: '#373a4b',
                        },
                        {
                            label: 'Vehicle Impressions',
                            data: data.map(item => Math.ceil(item.vehicle_count * 1.6 / item.days.length)),
                            backgroundColor: '#d48a02',
                        },
                    ]
                },
                options: {
                    "responsive": true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return value.toLocaleString();
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                                if (label) {
                                    label += ': ';
                                }
                                label += tooltipItem.yLabel.toLocaleString()
                                return label;
                            }
                        }
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import "src/sass/variables";

@media print {
    .buttons-header {
        display: none !important;
    }
}

#report {

    .report-header {
        display: flex;
        align-items: flex-start;

        .title span {
            color: #373a4b;
            text-transform: uppercase;
            line-height: 1.2;
        }

        .buttons {
            margin-left: auto;
            display: flex;
            align-items: center;

            > * {
                margin-left: 8px;
                min-width: 150px;
            }
        }
    }

    .report-info {
        color: #888787;

        > div {
            margin-top: 6px;
        }
    }

    .data-columns {
        > div {
            margin: 5px 0;
            display: grid;
            grid-gap: 10px;
            text-align: center;
        }

        > div:first-child {
            font-weight: 400;
            font-size: 16px;
            color: #888787;
            text-transform: uppercase;
        }
    }

    .chartdata2-columns {
        > div {
            grid-template-columns: 150px repeat(7, 1fr);
        }
    }

    .chartdata3-columns {
        > div {
            grid-template-columns: 150px repeat(24, 1fr);
            font-size: 12px;
        }
    }
}
</style>
