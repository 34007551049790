import Vue from 'vue'
import VueRouter from 'vue-router'
import Reporting from "../views/Reporting";
import Reports from "../views/Reporting/Reports";
import ReportCreate from "../views/Reporting/ReportCreate";
import Login from "../views/Auth/Login";
import ForgotPassword from "../views/Auth/ForgotPassword";
import PasswordReset from "../views/Auth/PasswordReset";
import Dashboard from "../views/Layouts/Dashboard";
import Auth from "../views/Layouts/Auth";
import Share from "../views/Layouts/Share";
import Profile from "../views/Settings/Profile";
import Users from "../views/Settings/Users";
import User from "../views/Settings/Users/User";
import Settings from "../views/Settings";
import Report from "../views/Reporting/Report";

Vue.use(VueRouter)

const routes = [
    /* Dashboard */
    {
        path: '/',
        component: Dashboard,
        name: 'Dashboard',
        redirect: '/reporting/create',
        children: [
            {
                path: '/reporting', component: Reporting,
                children: [
                    {
                        path: '/', component: Reports, name: "Reports",
                    },
                    {
                        path: 'create', component: ReportCreate, name: "ReportCreate",
                    },
                ]
            },
            {
                path: '/report/:hash', component: Report, name: "Report",
            },
            /* Settings */
            {
                path: '/settings',
                component: Settings,
                name: 'Settings',
                redirect: '/settings/profile',
                children: [
                    /**/
                    {
                        path: '/profile', name: 'Profile', component: Profile,
                    },
                    /* Users */
                    {
                        path: '/users', name: 'Users', component: Users,
                    },
                    {
                        path: '/users/:id', name: 'User', component: User,
                    },
                ],
            },
        ]
    },
    /* Share */
    {
        path: '/share',
        component: Share,
        children: [
            {
                path: 'report/:hash', component: Report, name: "Share-Report",
            },
        ]
    },
    /* Auth */
    {
        path: '/auth',
        component: Auth,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword
            },
            {
                path: 'password-reset/:token',
                name: 'PasswordReset',
                component: PasswordReset,
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
