<template>
    <div class="form-errors" v-if="errors">
        <div v-if="errors.message" class="message">{{ errors.message }}</div>
        <div v-if="errors.errors" class="errors">
            <div v-for="(values, key) in errors.errors" :key="key">
                <div class="error-name">{{ key }}:</div>
                <ul class="error-list">
                    <li v-for="(value, key2) in values" :key="key2">{{ value }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormErrors',
        props: {
            errors: {},
        }
    }
</script>

<style lang="scss">
    .form-errors {
        background: #ffcfcf;
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 25px;
        .message {
            margin-bottom: 4px;
        }
        .errors {
            .error-name {
                color: red;
                font-weight: 500;
                text-transform: capitalize;
                margin-bottom: 4px;
            }
            .error-list{
                color: red;
                li{
                    margin-left: 1rem;
                    list-style: circle;
                    margin-bottom: 4px;
                }
            }
        }

        .message {
            font-weight: 500;
            color: red;
        }
    }
</style>
