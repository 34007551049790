<template>
    <div class="label-image">
        <span class="label">{{ label }}</span>
        <input class="input"
               ref="file"
               type="file"
               accept="image/*"
               :name="name"
               :placeholder="label"
               :readonly="readonly"
               v-bind="attrs"
               :class="{'input-filled': inputFilled}"
               @blur="focus = false"
               @focus="focus = true"
               @change="image"
        />
        <field-error :errors="errors" :name="name" v-if="errors"></field-error>
    </div>
</template>

<script>
import FieldError from "./FieldError";

export default {
    components: {FieldError},
    props: {
        label: {
            required: true,
        },
        name: {
            required: true,
        },
        value: {},
        readonly: {
            default: false,
        },
        attrs: {},
        errors: {}
    },
    data() {
        return {
            focus: false,
            inputFilled: false,
        }
    },
    methods: {
        image() {
            let file = this.$refs.file.files[0];
            this.$emit('input', file);
        }
    },
    watch: {
        value(value) {
            if (value && !this.focus) {
                this.inputFilled = true;
            }
            if (!value) {
                this.$refs.file.value = '';
            }
        },
        focus() {
            this.inputFilled = false;
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

.label-image {
    margin-bottom: 25px;

    .label {
        display: block;
        font-size: $text;
        color: $dark;
        margin-bottom: 5px;
    }

    .input {
        display: block;
        width: 100%;
        letter-spacing: 0.4px;
    }

}
</style>
