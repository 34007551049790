<template>
    <div id="report-create">
        <form @submit.prevent="submitForm(false)">
            <div style="display: flex; width: 100%;">
                <div style="flex-grow: 1;">
                    <h1 class="title margin-bottom-15">
                        Campaign Credentials
                    </h1>
                    <div class="text-midgrey margin-bottom-55">Fill in the form below to produce campaign insights</div>
                </div>
                <div>

                    <div class="">
                        <div class="generate-buttons top">
                            <button @click.prevent="clearForm"
                                    class="btn btn-outline-grey" type="reset">Clear
                            </button>
                            <button class="btn btn-outline" type="button"
                                    :disabled="this.status != null"
                                    @click.prevent="submitForm(true)"
                            >Generate & Save
                            </button>
                            <button class="btn btn-primary"
                                    :disabled="this.status != null"
                            >Generate
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-max-width margin-bottom-55">
                <h2 class="title-2 margin-bottom-25">Campaign Information</h2>
                <LabelInput label="Campaign Name" name="name"
                            v-model="form.name"></LabelInput>
            </div>
            <div class="margin-bottom-55">
                <div>
                    <h2 class="title-2 margin-bottom-25">Sites & Timings</h2>
                </div>
                <div class="site-timings">
                    <div class="date-filter-container">
                        <div></div>
                        <div>
                            <label>Filter All</label>
                            <div class="daterangepicker-container input-max-width">
                                <date-range-picker
                                    :always-show-calendars="false"
                                    :locale-data="{ firstDay: 1, format: 'dd/mm/yyyy' }"
                                    v-model="date"
                                    minDate="2021-9-6"
                                    :maxDate="today"
                                ></date-range-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FormErrors :errors="errors"></FormErrors>
            <div class="">
                <div class="generate-buttons">
                    <button @click.prevent="clearForm"
                            class="btn btn-outline-grey" type="reset">Clear
                    </button>
                    <button class="btn btn-outline" type="button"
                            :disabled="this.status != null"
                            @click.prevent="submitForm(true)"
                    >Generate & Save
                    </button>
                    <button class="btn btn-primary"
                            :disabled="this.status != null"
                    >Generate
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import api from "../../utils/api";
import LabelInput from "../../components/Form/LabelInput";
import FormErrors from "../../components/Form/FormErrors";
import DateRangePicker from 'vue2-daterange-picker'

export default {
    components: {
        DateRangePicker,
        FormErrors, LabelInput
    },
    data() {
        return {
            form: {
                name: '',
                start_date: null,
                end_date: null,
            },
            date: {
                startDate: null,
                endDate: null,
            },
            errors: null,
            status: null,
        }
    },
    methods: {
        clearForm() {
            this.date = {
                startDate: null,
                endDate: null,
            }
        },
        submitForm(save) {
            this.status = 'Saving';
            this.errors = null;
            //
            api.post('/api/reports', {
                save,
                ...this.form
            }).then(response => {
                this.$router.push({
                    name: 'Report',
                    params: {
                        hash: response.data.report.hash
                    }
                });
            }).catch(error => {
                this.status = null;
                this.errors = error.response.data;
            });
        },
    },
    computed: {
        today() {
            return (new Date).toString()
        }
    },
    watch: {
        date: {
            handler(date) {
                this.form.start_date = date.startDate;
                this.form.end_date = date.endDate;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

#report-create {

    .additional-info-container {
        @media (min-width: 700px) {

            columns: 2;
            .additional-info {
                break-inside: avoid;
            }
        }

        .additional-info {
            margin-bottom: 35px;
            font-size: $text;

            .additional-info-title {
                font-weight: $semibold;
                margin-bottom: 15px;
            }

            .additional-info-selects {
                label {
                    margin-bottom: 15px;
                    color: $greyfont;
                }
            }
        }
    }

    .generate-buttons {
        display: flex;
        justify-content: flex-end;

        &.top {
            @media (max-width: 1400px) {
                display: none;
            }
        }

        button {
            margin-left: 10px;
            min-width: 140px;
        }
    }


    .site-timings {

        .date-filter-container {
            margin-bottom: 35px;

            label {
                display: block;
                font-size: 16px;
                color: #373a4b;
                margin-bottom: 5px;
                letter-spacing: 0.2px;
            }

            .daterangepicker-container {
                position: relative;

                &.disabled {
                    opacity: .3;
                }
            }
        }

        .checkbox-container {
            display: inline-block;
        }

        .btn-clear {
            border: none;
            background: none;
            padding: 0px;
            cursor: pointer;
            color: #888787;
            position: absolute;
            letter-spacing: 0.2px;
            bottom: 50%;
            right: 5px;
            transform: translate(0, 50%);
            transition: 0.2s all;

            &:hover {
                opacity: 0.6;
            }

            &:focus {
                outline: none;
            }
        }

        .citys-locations-container {
            .citys-locations {
                margin-bottom: 35px;
            }

            .camera-row {
                display: flex;
                margin-bottom: 12px;

                .location {
                    width: 40%;
                }

                .date-ranges {
                    width: 60%;

                    > div {
                        display: flex;
                        margin-bottom: 5px;

                        .date {
                            width: 240px;
                            position: relative;

                            .vue-daterange-picker {
                                width: 100%;
                            }
                        }

                        .buttons {
                            width: 5%;

                            .btn-add, .btn-remove {
                                height: 100%;
                                border: none;
                                background: none;
                                color: $colour;
                                font-size: 36px;
                                text-align: center;
                                width: 30px;
                                cursor: pointer;
                                transition: 0.2s all;

                                &:hover {
                                    color: $colourhover;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }

                            .btn-remove {
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
        }

        .location {
            color: $midgrey;
        }
    }

    .checkbox-container.city {
        strong {
            text-transform: uppercase;
        }
    }

    @media only screen and (max-width: $mobile) {
        .generate-buttons {
            flex-direction: column;

            button {
                margin-left: unset;
                margin-top: 10px;
            }
        }
    }
}
</style>
