<template>
    <div id="login">
        <h1 class="title">Sign in</h1>
        <form @submit.prevent="login">
            <LabelInput
                :errors="errors"
                label="Email"
                name="email"
                v-model="form.email"
            ></LabelInput>
            <LabelInput
                :errors="errors"
                label="Password"
                name="password"
                type="password"
                v-model="form.password"
            ></LabelInput>
            <div class="remember-forgot">
                <label>
                    <input type="checkbox" name="remember" v-model="form.remember" value="1">
                    Remember Me
                </label>
                <div>
                    <router-link :to="{name: 'ForgotPassword'}">Forgot Your Password?</router-link>
                </div>
            </div>
            <div class="buttons">
                <button :disabled="busy" class="btn">Login</button>
            </div>
        </form>
    </div>
</template>
<script>
import LabelInput from "../../components/Form/LabelInput";
import api from "../../utils/api";

export default {
    components: {LabelInput},
    data() {
        return {
            busy: false,
            errors: null,
            form: {
                email: '',
                password: '',
                remember: false,
            }
        }
    },
    methods: {
        login() {
            this.busy = true;
            this.errors = null;
            api.get('/sanctum/csrf-cookie').then(() => {
                api.post('/auth/login', this.form)
                    .then(() => {
                        this.$store.dispatch('USER').then(() => {
                            this.$router.push({name: 'Dashboard'});
                        });
                    })
                    .catch(error => {
                        if (error.response.data.errors) {
                            this.errors = error.response.data.errors;
                        }
                        this.busy = false;
                    })
            })
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

#login {
    .remember-forgot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }
}
</style>
