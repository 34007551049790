<template>
    <div class="label-input" :class="type">
        <span class="label">{{ label }}</span>
        <input class="input"
               :type="type"
               :name="name"
               :placeholder="label"
               :readonly="readonly"
               v-bind="attrs"
               v-bind:value="value"
               v-on:input="$emit('input', $event.target.value)"
               :class="{'input-filled': inputFilled}"
               @blur="focus = false"
               @focus="focus = true"
        />
        <field-error :errors="errors" :name="name" v-if="errors"></field-error>
    </div>
</template>

<script>
import FieldError from "./FieldError";
export default {
    name: 'LabelInput',
    components: {FieldError},
    props: {
        label: {
            required: true,
        },
        name: {
            required: true,
        },
        value: {},
        type: {
            default: 'text',
        },
        readonly: {
            default: false,
        },
        attrs: {},
        errors: {}
    },
    data() {
        return {
            focus: false,
            inputFilled: false,
        }
    },
    watch: {
        value() {
            if (!this.focus) {
                this.inputFilled = true;
            }
        },
        focus() {
            this.inputFilled = false;
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

.label-input {
    margin-bottom: 25px;

    .label {
        display: block;
        font-size: $text;
        color: $dark;
        margin-bottom: 5px;
    }

    .input {
        display: block;
        width: 100%;
        letter-spacing: 0.4px;
    }
}
</style>
