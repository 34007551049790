<template>
    <div class="index-table-container">
        <table class="index-table margin-bottom-25">
            <thead>
            <tr class="fadeup">
                <th v-for="(name, key) in data.columns" :class="'column-'+key" :key="'column-'+key">
                    {{ name }}
                </th>
            </tr>
            </thead>
            <tbody v-for="(item,index) in data.data" :key="'item'+(item.id?item.id:index)">
            <tr class="fadeup">
                <td v-for="key in Object.keys(data.columns)" :class="'column-'+key" :key="'column-'+key">
                    <slot v-bind:item="item" :name="'column-'+key">
                        {{ item[key] }}
                    </slot>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'IndexTable',
        props: {
            data: {
                required: true,
            }
        }
    }
</script>

<style lang="scss">
    @import "src/sass/variables";

    .index-table-container {
        overflow: auto;
    }

    .index-table {
        width: 100%;
        font-size: $text;

        thead {
            th {
                text-align: left;
                font-size: $text;
                color: $midgrey;
                letter-spacing: $letterspacing;
                padding-bottom: 6px;
                padding-left: 4px;
                padding-right: 4px;
                border-bottom: 1px solid rgba($dark, 0.1);
            }
        }

        thead, tbody {
            td {
                padding-left: 4px;
                padding-right: 4px;
                border-bottom: 1px solid rgba($dark, 0.1);
                height: 52px;
                vertical-align: middle;
            }
        }

        tbody {
            tr {
                transition: 0.4s all;

                &:hover {
                    background: #f1f1f199;
                }
            }
        }

        .column-actions {
            .delete {
                margin-right: 8px;
                opacity: 0.6;
                transition: 0.4s all;

                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }

            }
        }
    }
</style>
