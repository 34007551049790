import './sass/app.scss'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './router/rules.router'
import store from './store'

Vue.config.productionTip = false

import InlineSvg from 'vue-inline-svg';

Vue.component('inline-svg', InlineSvg);

import ClickOutside from 'vue-click-outside'
Vue.directive('click-outside', ClickOutside);

(async () => {
    await store.dispatch('USER');

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
})()
