<template>
    <div id="user-nav-bar" v-if="user"
         @click="menuOpen = !menuOpen" v-click-outside="closeMenu"
    >
        <div class="user">
            <div class="title-2">{{ user['name'] }}</div>
        </div>
        <div class="viewer-nav-bar-menu">
            <div class="menu-btn">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.22 347.1" class="arrow">
                    <path class="cls-1"
                          d="M13.67,347.1a13.64,13.64,0,0,0,9.67-4L183.22,183.22a13.68,13.68,0,0,0,0-19.33L23.34,4A13.67,13.67,0,0,0,4,23.34L154.22,173.55,4,323.76a13.68,13.68,0,0,0,9.66,23.34Z"/>
                </svg>
            </div>
            <div class="submenu"
                 v-show="menuOpen">
                <div class="top">
                    <div class="title-2">Settings</div>
                </div>
                <div class="content">
                    <router-link :to="{name: 'Profile'}" class="link">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 104.5" class="profile-icon">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-2"
                                              d="M82.5,50.61H61.82a28.93,28.93,0,0,1-30.64,0H10.5A10.51,10.51,0,0,0,0,61.11V94a10.51,10.51,0,0,0,10.5,10.5h72A10.51,10.51,0,0,0,93,94V61.11A10.51,10.51,0,0,0,82.5,50.61Z"/>
                                        <path class="cls-2"
                                              d="M32.05,47.61a29.38,29.38,0,0,0,2.55,1.5,26.78,26.78,0,0,0,3.52,1.5,26,26,0,0,0,16.76,0,26.78,26.78,0,0,0,3.52-1.5A29.38,29.38,0,0,0,61,47.61a26,26,0,1,0-28.9,0Z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="title-2">Profile</div>
                    </router-link>
                    <button @click="logout" class="btn btn-primary width-100 margin-top-15">Log Out</button>
                </div>
            </div>
        </div>
        <div class="avatar" :style="imageStyle">

        </div>
    </div>
</template>

<script>

import api from "../../../utils/api";

export default {
    name: 'UserNavBar',
    components: {},
    data() {
        return {
            menuOpen: false
        }
    },
    mounted() {

    },
    methods: {
        closeMenu() {
            this.menuOpen = false;
        },
        logout() {
            api.post('/auth/logout').then(() => {
                location.href = '/dashboard/';
            })
        },
    },
    computed: {
        user() {
            return this.$store.state.user.user;
        },
        imageStyle() {
            let backgroundImage = '';
            if (this.user && this.user.avatar) {
                let url = process.env.NODE_ENV === 'production' ? '' : 'http://jolt-lens.localhost';
                url += '/storage/thumbnail/' + this.user.avatar;
                backgroundImage = `url('${url}')`;
            }
            return {
                backgroundImage: backgroundImage,
            }
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

#user-nav-bar {
    cursor: pointer;
    margin-bottom: 2px;
    font-size: $text;
    color: $dark;
    display: flex;
    align-items: center;
    margin-right: 25px;

    svg {
        height: 100%;
        width: 100%;

        .cls-1 {
            opacity: 0.6;

            polygon, path {
                opacity: 0.6;
            }
        }

        .cls-2 {
            opacity: 1;
        }

        &.profile-icon {
            padding: 2px;
        }
    }

    .user {

    }

    .avatar {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #ffffff url("../../../assets/Lens_Logo.jpeg") center no-repeat;
        background-size: cover;
        box-shadow: 2px 2px 6px #00000047;
        margin-left: 10px;
    }

    .viewer-nav-bar-menu {
        position: relative;

        .menu-btn {
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            bottom: 0;
            margin-left: 2px;

            .arrow {
                transform: rotate(90deg);
                height: 100%;
                margin: auto;
                padding: 15%;

                path {
                    fill: $grey;
                }
            }
        }

        .submenu {
            position: absolute;
            background: white;
            border-radius: 5px;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
            right: -14px;
            top: 30px;
            text-align: left;
            width: 220px;

            &:before {
                position: absolute;
                top: -0.75rem;
                right: 5px;
                width: 0;
                height: 0;
                margin: 0 auto;
                border-left: 1em solid transparent;
                border-right: 1em solid transparent;
                border-bottom: 1em solid $dark;
                content: "";
            }

            .image {
                position: absolute;
                margin-left: 15px;
                margin-top: 15px;

                img {
                    height: 50px;
                }
            }

            .top {
                height: 42px;
                display: flex;
                align-items: center;
                background-color: $dark;
                font-weight: 500;
                padding-left: 12px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .title-2 {
                    color: white;
                }
            }
        }

        .content {
            padding: 15px;

            > div {
                cursor: pointer;
                padding-top: 8px;
                padding-bottom: 5px;
            }

            .link {
                color: $dark;
                transition: 0.4s all;
                display: flex;
                align-items: center;
                cursor: pointer;

                .icon {
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;

                    svg {
                        path {
                            transition: 0.4s all;
                            fill: $midgrey;
                        }
                    }
                }

                .title-2 {
                    transition: 0.4s all;
                    color: $midgrey;
                }

                &:hover {
                    .title-2 {
                        color: $dark;
                    }

                    svg {
                        path {
                            fill: $dark;
                        }
                    }
                }
            }
        }
    }
}

</style>
