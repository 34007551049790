<template>
    <TwoColumnsBoxed id="settings" class="fadeup">
        <template v-slot:column-left>
            <div class="box">
                <h1 class="title margin-bottom-35">Settings</h1>
                <div class="second-nav">
                    <router-link :to="{name: 'Profile'}">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 104.5" class="profile-icon">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-2"
                                              d="M82.5,50.61H61.82a28.93,28.93,0,0,1-30.64,0H10.5A10.51,10.51,0,0,0,0,61.11V94a10.51,10.51,0,0,0,10.5,10.5h72A10.51,10.51,0,0,0,93,94V61.11A10.51,10.51,0,0,0,82.5,50.61Z"/>
                                        <path class="cls-2"
                                              d="M32.05,47.61a29.38,29.38,0,0,0,2.55,1.5,26.78,26.78,0,0,0,3.52,1.5,26,26,0,0,0,16.76,0,26.78,26.78,0,0,0,3.52-1.5A29.38,29.38,0,0,0,61,47.61a26,26,0,1,0-28.9,0Z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="title-2">Profile</div>
                    </router-link>
                    <router-link :to="{name: 'Users'}" v-if="user.type === 'Admin'">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.73 89.26">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <path class="cls-2"
                                              d="M89.9,51.41a11.15,11.15,0,0,0-8.41-3.85H68.58c-.37.23-.74.44-1.12.64a23.16,23.16,0,0,1-22.19,0c-.38-.2-.75-.41-1.11-.64H31.24A11.2,11.2,0,0,0,20.09,58.71v19.4A11.16,11.16,0,0,0,31.24,89.26H81.49A11.16,11.16,0,0,0,92.64,78.11V58.71A11.11,11.11,0,0,0,89.9,51.41Z"/>
                                        <path class="cls-2"
                                              d="M76,23.36a20.09,20.09,0,0,0-39.21,0,19.8,19.8,0,0,0-.5,4.38,20.12,20.12,0,0,0,8.83,16.64,19.1,19.1,0,0,0,1.75,1c.36.2.73.39,1.1.56a19.57,19.57,0,0,0,5.21,1.59,18,18,0,0,0,6.43,0A19.57,19.57,0,0,0,64.79,46c.37-.17.74-.36,1.1-.56a19.1,19.1,0,0,0,1.75-1,20.12,20.12,0,0,0,8.83-16.64A19.8,19.8,0,0,0,76,23.36Z"/>
                                        <g class="cls-2">
                                            <path class="cls-1"
                                                  d="M31.24,47.56H44.16c.36.23.73.44,1.11.64a9,9,0,0,0,1.57-2.79,19.1,19.1,0,0,1-1.75-1,20.12,20.12,0,0,1-8.83-16.64,19.8,19.8,0,0,1,.5-4.38H33.62a15.06,15.06,0,0,1-19.91,0H8.89A8.92,8.92,0,0,0,0,32.25V42.52a8.92,8.92,0,0,0,8.89,8.89h14A11.11,11.11,0,0,1,31.24,47.56Z"/>
                                            <path class="cls-1"
                                                  d="M20,23.36A12,12,0,1,0,11.7,12,12,12,0,0,0,20,23.36Z"/>
                                            <path class="cls-1"
                                                  d="M103.84,23.36H99a15.06,15.06,0,0,1-19.91,0H76a19.8,19.8,0,0,1,.5,4.38,20.12,20.12,0,0,1-8.83,16.64,19.1,19.1,0,0,1-1.75,1,9,9,0,0,0,1.57,2.79c.38-.2.75-.41,1.12-.64H81.49a11.15,11.15,0,0,1,8.41,3.85h13.94a8.92,8.92,0,0,0,8.89-8.89V32.25A8.92,8.92,0,0,0,103.84,23.36Z"/>
                                            <path class="cls-1"
                                                  d="M85.41,23.36A12,12,0,1,0,77.1,12,12,12,0,0,0,85.41,23.36Z"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="title-2">Users</div>
                    </router-link>
                </div>
            </div>
        </template>
        <template v-slot:column-right>
            <router-view></router-view>
        </template>
    </TwoColumnsBoxed>
</template>

<script>
import TwoColumnsBoxed from "../components/Skeleton/TwoColumnsBoxed";

export default {
    name: 'Settings',
    components: {TwoColumnsBoxed},
    data() {
        return {};
    },
    mounted() {

    },
    methods: {},
    computed: {
        user() {
            return this.$store.state.user.user;
        }
    },
    watch: {},
}
</script>

<style lang="scss">
@import "src/sass/variables";

#settings {
    svg {
        height: 100%;
        width: 100%;

        .cls-1 {
            opacity: 0.6;

            polygon, path {
                opacity: 0.6;
            }
        }

        .cls-2 {
            opacity: 1;
        }

        &.profile-icon {
            padding: 2px;
        }
    }
}
</style>
