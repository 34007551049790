<template>
    <div id="app-top-nav">
        <div class="logo-container">
            <div class="nav-logo">
                <inline-svg :src="require('../../assets/logo.svg')"/>
            </div>
        </div>
        <div v-if="user">
            <user-nav-bar></user-nav-bar>
        </div>
    </div>
</template>

<script>
import UserNavBar from "./AppTopNav/UserNavBar";

export default {
    name: 'AppTopNav',
    components: {
        UserNavBar
    },
    computed: {
        user() {
            return this.$store.state.user.user;
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

#app-top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-container {
        height: 100%;
        width: 140px;
        display: flex;
        align-items: center;

        .nav-logo {
            width: 70%;
            height: 100%;
            margin: auto;
            display: flex;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        svg {
            width: 100%;

            path, polygon {
                fill: $dark;
            }
        }
    }

    @media only screen and (max-width: 1280px) {
        .logo-container {
            width: 120px;
        }
    }
}
</style>
