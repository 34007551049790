<template>
    <form @submit.prevent="save" class="">
        <h2 class="title-2 margin-bottom-25">Password</h2>
        <div>
            <LabelInput
                :errors="errors"
                label="Current Password"
                name="password"
                type="password"
                v-model="form.current_password"
                autocomplete="off"
            ></LabelInput>
            <LabelInput
                :errors="errors"
                label="Password"
                name="password"
                type="password"
                v-model="form.password"
                autocomplete="off"
            ></LabelInput>
            <LabelInput
                :errors="errors"
                label="Password"
                name="password_confirmation"
                type="password"
                v-model="form.password_confirmation"
                autocomplete="off"
            ></LabelInput>
            <div class="success" v-if="success">
                {{ success }}
            </div>
        </div>
        <div class="buttons">
            <button :disabled="busy" class="btn btn-primary">Save</button>
        </div>
    </form>
</template>

<script>

import LabelInput from "../../../components/Form/LabelInput";
import api from "../../../utils/api";

export default {
    name: 'ProfilePassword',
    components: {LabelInput},
    data() {
        return {
            busy: false,
            errors: null,
            success: null,
            form: {
                current_password: '',
                password: '',
                password_confirmation: '',
            }
        }
    },
    methods: {
        save() {
            this.busy = true;
            api.put('/auth/user/password', this.form)
                .then(() => {
                    for (let key in this.form) {
                        this.form[key] = '';
                    }
                    this.success = 'Saved';
                    this.timer = setTimeout(() => {
                        this.busy = false;
                        this.success = null;
                    }, 3000);
                })
                .catch(error => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }
                    this.busy = false;
                })
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>
