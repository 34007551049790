<template>
    <div>
        <div id="TwoColumnsBoxed">
            <div class="column-left">
                <slot name="column-left"></slot>
            </div>
            <div class="column-right">
                <div class="box">
                <slot name="column-right"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TwoColumnsBoxed',
        components: {},
        data() {
            return {};
        },
        mounted() {
        },
        methods: {},
        computed: {},
        watch: {},

    }
</script>

<style lang="scss">
    #TwoColumnsBoxed {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 25px;


        > .column-left {
        }

        > .column-right {
            grid-column: 2/4;
            min-height: 700px;
            .box {
                min-height: 700px;
            }
        }

        .box {
            box-sizing: border-box;
            box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.160156);
            border-radius: 12px;
            padding: 22px;
        }
    }

    @media screen and (max-width: 768px) {
        #TwoColumnsBoxed {
            .column-left, .column-right {
                grid-column: 1/4;
            }

            > .column-right {
                min-height: unset;
                .box {
                    min-height: unset;
                }
            }

            .columns {
                .column-left {
                    flex: 100%;
                }
            }
        }
    }
</style>
