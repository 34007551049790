import axios from "axios";

const api = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '/' : 'http://jolt-lens.localhost',
    withCredentials: true,
    headers: {
        "content-type": "application/json",
    }
});

export default api;
