<template>
    <div id="reports" class="fadeup">
        <div class="header margin-bottom-55">
            <h1 class="title">Reports</h1>
            <router-link :to="{name: 'ReportCreate'}" class="btn btn-primary">
                + Create Report
            </router-link>
        </div>
        <div v-if="tableData.data">
            <IndexTable v-if="tableData.data.length"
                        :data="tableData"
            >
                <template v-slot:column-actions="{item}">
                    <span class="action-link delete" @click="deleteItem(item)">
                        <svg>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                               stroke-linecap="square">
                                <g id="Report-SavedReports" transform="translate(-1303.000000, -401.000000)"
                                   stroke="#ED9B00" stroke-width="2">
                                    <g id="Group-3"
                                       transform="translate(1312.292893, 410.535534) rotate(45.000000) translate(-1312.292893, -410.535534) translate(1301.292893, 399.535534)">
                                        <path
                                            d="M0.514718626,10.8284271 L20.3137085,10.8284271 M10.4142136,0.928932188 L10.4142136,20.7279221"
                                            id="Combined-Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                    <router-link :to="{name:'Report', params: {hash:item.hash}}" class="action-link">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.64 213.27" class="open-icon">
                            <polygon class="cls-1"
                                     points="198.24 124.43 178.24 124.43 178.24 193.27 20 193.27 20 35.02 90.2 35.02 90.2 15.02 0 15.02 0 213.27 198.24 213.27 198.24 124.43"/>
                            <polygon
                                points="142.22 0 142.22 20 180.49 20 91.66 108.83 105.81 122.97 194.63 34.14 194.63 72.42 214.63 72.42 214.63 0 142.22 0"/>
                        </svg>
                    </router-link>
                </template>
            </IndexTable>
            <div v-else>Empty</div>
        </div>
    </div>
</template>

<script>
import IndexTable from "../../components/Index/IndexTable";
import api from "../../utils/api";

export default {
    components: {
        IndexTable
    },
    data() {
        return {
            filters: null
        };
    },
    mounted() {
        api.get('/api/filters').then(response => {
            this.filters = response.data.filters;
        })
    },
    methods: {
        deleteItem(item) {
            if (!confirm('Are you sure?')) return;
            this.filters = this.filters.filter(i => item.id != i.id);
            api.delete('/api/filters/' + item.id);
        }
    },
    computed: {
        tableData() {
            return {
                columns: {
                    'name': 'Campaign Name',
                    'actions': '',
                },
                data: this.filters
            }
        }
    },
    watch: {},
}
</script>

<style lang="scss">
#reports {

}
</style>
