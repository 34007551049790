<template>
    <label class="label-select">
        <span class="label">{{ label }}</span>
        <div>
            <select :name="name" class="select" v-model="localValue">
                <template v-if="options">
                    <option v-for="(option, key) in options" :key="key"
                            :value="key">
                        {{ option }}
                    </option>
                </template>
            </select>
        </div>
    </label>
</template>

<script>
export default {
    name: 'LabelSelect',
    props: {
        label: {
            required: true,
        },
        name: {
            required: true,
        },
        value: {
            required: true,
        },
        options: {},
    },
    data() {
        return {
            localValue: this.value
        }
    },
    watch: {
        localValue(value) {
            this.$emit('input', value);
        }
    }
}
</script>

<style lang="scss">
@import "src/sass/variables";

.label-select {
    margin-bottom: 25px;

    .label {
        display: block;
        font-size: $text;
        color: $dark;
        margin-bottom: 5px;
    }

    div {
        position: relative;
        margin-bottom: 25px;

        &:after {
            content: "";
            height: 10px;
            width: 10px;
            border-right: 1px solid $dark;
            border-bottom: 1px solid $dark;
            position: absolute;
            top: calc(50% - 8px);
            right: 20px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    select {
        width: 100%;
        display: inline-block;
        border: 1px solid #CBCBCB;
        background: white;
        border-radius: 0 !important;
        padding: 8px;
        padding-left: 12px;
        background-color: white;
        height: 38px;
        font-size: $text;
        color: $dark;
        letter-spacing: $letterspacing;
        -webkit-appearance: none;
    }


    /*.select {*/
    /*    display: block;*/
    /*    width: 100%;*/
    /*    border: 1px solid #CBCBCB;*/
    /*    background-color: white;*/
    /*}*/
}
</style>
