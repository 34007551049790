<template>
    <div id="profile" class="wrapper page">
        <h1 class="title margin-bottom-55">Profile</h1>
        <div id="profile-content">
            <profile-details></profile-details>
            <profile-password></profile-password>
        </div>
    </div>
</template>

<script>
import ProfileDetails from "./Profile/ProfileDetails";
import ProfilePassword from "./Profile/ProfilePassword";

export default {
    components: {ProfilePassword, ProfileDetails},
    data() {
        return {}
    }
}
</script>

<style lang="scss">
#profile {
    #profile-content {
        > * {
            display: block;
            margin-bottom: 55px;
        }
    }
}
</style>
