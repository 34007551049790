<template>
    <div v-if="error" class="field-error">
        <div class="error" v-for="(value, index) in error" :key="index">{{ value }}</div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            required: true,
        },
        errors: {
            required: true,
        }
    },
    computed: {
        error() {
            if (!this.errors) return;
            if (!this.errors[this.name]) return;
            return this.errors[this.name];
        }
    }
}
</script>

<style lang="scss">
.field-error{
    .error{
        color: red;
        margin-top: 5px;
    }
}
</style>
