import api from "../utils/api";

export default {

    state: {
        user: null
    },
    mutations: {
        user: (state, payload) => state.user = payload,
    },
    actions: {
        async USER(context) {
            try {
                const response = await api.get('/api/user');
                context.commit('user', response.data.user);
            } catch (e) {
                context.commit('user', null);
            }
            return context.state.user;
        },
    },
}
