<template>
    <form @submit.prevent="save" class="profile-details">
        <h2 class="title-2 margin-bottom-25">Details</h2>
        <div>
            <div class="profile-details-grid">
                <div>
                    <LabelInput
                        :errors="errors"
                        label="Name"
                        name="name"
                        v-model="form.name"
                    ></LabelInput>
                    <LabelInput
                        :errors="errors"
                        label="Email"
                        name="email"
                        type="email"
                        v-model="form.email"
                    ></LabelInput>
                    <div class="success" v-if="success">
                        {{ success }}
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button :disabled="busy" class="btn btn-primary">Save</button>
        </div>
    </form>
</template>

<script>
import LabelInput from "../../../components/Form/LabelInput";
import api from "../../../utils/api";

export default {
    components: {LabelInput,},
    data() {
        return {
            busy: false,
            errors: null,
            success: null,
            form: {
                name: this.$store.state.user.user.name,
                email: this.$store.state.user.user.email,
            }
        }
    },
    methods: {
        save() {
            this.busy = true;
            api.put('../auth/user/profile-information', this.form)
                .then(() => {
                    this.success = 'Saved';
                    this.timer = setTimeout(() => {
                        this.busy = false;
                        this.success = null;
                    }, 3000);
                })
                .catch(error => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }
                    this.busy = false;
                })
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style lang="scss">
.profile-details {

}
</style>
