<template>
    <div id="users" class="wrapper page">
        <div class="header margin-bottom-55">
            <h1 class="title">Users</h1>
            <router-link :to="{name: 'User', params:{id: 'create'}}" class="btn btn-primary">
                + Create User
            </router-link>
        </div>
        <div>
            <IndexTable v-if="tableData.data.length"
                        :data="tableData"
            >
                <template v-slot:column-avatar="{item}">
                    <img :src="avatar(item)" alt="" v-if="item.avatar">
                </template>
                <template v-slot:column-actions="{item}">
                    <span class="action-link delete" @click="deleteItem(item)" v-if="item.id !== user.id">
                        <svg>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                               stroke-linecap="square">
                                <g id="Report-SavedReports" transform="translate(-1303.000000, -401.000000)"
                                   stroke="#ED9B00" stroke-width="2">
                                    <g id="Group-3"
                                       transform="translate(1312.292893, 410.535534) rotate(45.000000) translate(-1312.292893, -410.535534) translate(1301.292893, 399.535534)">
                                        <path
                                            d="M0.514718626,10.8284271 L20.3137085,10.8284271 M10.4142136,0.928932188 L10.4142136,20.7279221"
                                            id="Combined-Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                    <router-link :to="{name:'User', params: {id:item.id}}" class="action-link">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.64 213.27" class="open-icon">
                            <polygon class="cls-1"
                                     points="198.24 124.43 178.24 124.43 178.24 193.27 20 193.27 20 35.02 90.2 35.02 90.2 15.02 0 15.02 0 213.27 198.24 213.27 198.24 124.43"/>
                            <polygon
                                points="142.22 0 142.22 20 180.49 20 91.66 108.83 105.81 122.97 194.63 34.14 194.63 72.42 214.63 72.42 214.63 0 142.22 0"/>
                        </svg>
                    </router-link>
                </template>
            </IndexTable>
        </div>
    </div>
</template>
<script>
import IndexTable from "../../components/Index/IndexTable";
import api from "../../utils/api";

export default {
    components: {IndexTable},

    data() {
        return {
            users: [],
        }
    },
    mounted() {
        api.get('/api/users').then(response => {
            this.users = response.data.users;
        });
    },
    methods: {
        deleteItem(item) {
            if (!confirm('Are you sure you want to delete: ' + item.name)) return;
            let index = this.users.findIndex(item2 => item2.id === item.id);
            if (index >= 0) this.users.splice(index, 1);
            api.delete('/api/users/' + item.id).catch(() => {
                alert('Something went wrong.');
                window.location.reload();
            })
        },
        avatar(item) {
            if (item.avatar) {
                let url = process.env.NODE_ENV === 'production' ? '' : 'http://jolt-lens.localhost';
                url += '/storage/thumbnail/' + item.avatar;
                return url;
            }
            return;
        }
    },
    computed: {
        tableData() {
            return {
                columns: {
                    'avatar': 'Avatar',
                    'name': 'Name',
                    'actions': '',
                },
                data: this.users
            }
        },
        user() {
            return this.$store.state.user.user;
        }
    },
}
</script>

<style lang="scss">
#users {
    .column-avatar {
        width: 32px;
        text-align: center;

        img {
            width: 100%;
        }
    }
}
</style>
