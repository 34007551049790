<template>
    <div class="auth">
        <h1 class="title">Forgot password</h1>
        <form @submit.prevent="login" v-if="!success">
            <LabelInput :errors="errors" v-model="form.email" label="Email" name="email"></LabelInput>
            <div>
                <button :disabled="busy" class="btn">Send</button>
            </div>
        </form>
        <div v-else class="description success">
            {{ success }}
        </div>
        <div style="margin-top: 15px; text-align: center">
            <router-link :to="{name: 'Login'}">Back to Login</router-link>
        </div>
    </div>
</template>
<script>
import LabelInput from "../../components/Form/LabelInput";
import api from "../../utils/api";

export default {
    name: 'ForgotPassword',
    components: {LabelInput},
    data() {
        return {
            busy: false,
            errors: null,
            success: null,
            form: {
                email: '',
            }
        }
    },
    methods: {
        login() {
            this.busy = true;
            this.errors = null;
            api.get('../sanctum/csrf-cookie').then(() => {
                api.post('../auth/forgot-password', this.form)
                    .then(() => {
                        this.success = 'E-mail sent.';
                    })
                    .catch(error => {
                        if (error.response.data.errors) {
                            this.errors = error.response.data.errors;
                        }
                        this.busy = false;
                    })
            })
        }
    }
}
</script>
