import router from "./index";
import store from "../store";

router.beforeEach((to, from, next) => {
    if (to.path.indexOf('/share') === 0) {
        next();
        return;
    }
    const user = store.state.user.user;
    if (user) {
        /**
         * Auth
         */
        if (
            to.path === '/' ||
            to.path.indexOf('/auth') === 0
        ) {
            next({
                name: 'Dashboard'
            });
            return;
        }
    } else {
        if (
            to.path !== '/' &&
            to.path.indexOf('/auth') !== 0
        ) {
            next({
                name: 'Login'
            });
            return;
        }
    }

    next();
});
