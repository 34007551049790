<template>
    <div id="app-grid-share">
        <div id="app-top-nav-share">
            <div class="logo-container">
                <div class="nav-logo">
                    <inline-svg :src="require('../../assets/logo.svg')"/>
                </div>
            </div>
        </div>
        <div id="app-content-share" :class="{'print-mode': printMode}">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {};
    },
    mounted() {

    },
    methods: {},
    computed: {
        printMode(){
            return !!this.$route.query.print;
        }
    },
    watch: {},

}
</script>

<style lang="scss">
@import "src/sass/variables";

#app-grid-share {
    display: block;

    #app-top-nav-share {
        background: white;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
        position: sticky;
        top: 0;
        z-index: 999;

        .nav-logo {
            padding: 0 20px;
            height: 80px;
            width: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            width: auto;

            * {
                fill: #373a4b;
            }
        }
    }

    #app-content-share {
        padding: 25px;
    }


    .buttons-header {
        position: fixed;
        right: 25px;
        top: 20px;
        z-index: 99999;
    }
}

@page {
    size: 1754px 1240px;
}

.print-mode {
    width: 1754px;
}

@media print {

    #app-top-nav-share {
        display: none;
    }

    .card {
        break-inside: avoid;
    }
}

</style>
