<template>
    <div id="app-grid">
        <app-top-nav></app-top-nav>
        <div id="app-nav">
            <div class="nav-items">
                <router-link :to="{name: 'ReportCreate'}" class="nav-item">
                    <div class="icon">
                        <inline-svg :src="require('../../assets/svg/reporting.svg')"/>
                    </div>
                    <div class="label">Reporting</div>
                </router-link>
            </div>
        </div>
        <div id="app-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

import AppTopNav from "../App/AppTopNav";
export default {
    components: {
        AppTopNav
    },
    data() {
        return {};
    },
    mounted() {

    },
    methods: {},
    computed: {},
    watch: {},

}
</script>

<style lang="scss">
@import "src/sass/variables";

html, body {
    padding: 0;
    margin: 0;
    font-size: 14px;
}

a {
    text-decoration: none;
}

//instagram page
.nav-background {
    position: absolute;
    z-index: -1;

    .menu {
        & > div {
            display: block;
            text-align: center;
            padding: 20px;
            margin-bottom: 15px;
        }
    }
}

#app-grid {
    display: grid;
    grid-template-columns: 140px calc(100vw - 140px);
    grid-template-rows: 80px auto;

    width: 100vw;
    height: 100vh;

    @media only screen and (max-width: $mobile) {
        grid-template-rows: 80px 80px auto;
    }

    #app-nav {
        grid-area: 2 / 1 / 3 / 2;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        z-index: 999;
        background: $dark;

        .nav-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 60px;

            .nav-item {
                font-size: $titles;
                font-weight: $semibold;
                letter-spacing: $letterspacing-titles;
                text-align: center;
                color: $grey;
                margin-bottom: 55px;
                transition: 0.4s all;

                .icon {
                    margin-bottom: 4px;

                    svg {
                        width: 40px;
                        margin: auto;

                        .cls-1 {
                            opacity: 0.6;
                        }

                        .cls-2 {
                            opacity: 1;
                        }

                        path, polygon {
                            fill: $grey;
                            transition: 0.4s all;
                        }
                    }
                }

                &:hover {
                    color: white;
                    opacity: 0.8;

                    .icon {
                        svg {
                            path {
                                fill: white;
                                opacity: 0.8;
                            }
                        }
                    }
                }

                &.router-link-active {
                    color: white;

                    .icon {
                        svg {
                            path {
                                fill: white;
                            }
                        }
                    }

                    &:hover {
                        color: white;
                        opacity: 1;

                        .icon {
                            svg {
                                path {
                                    fill: white;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #app-top-nav {
        grid-area: 1 / 1 / 2 / 3;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
        position: sticky;
        top: 0;
        z-index: 999;
    }

    #app-content {
        grid-area: 2 / 2 / 3 / 3;
        padding: 25px;
        overflow: auto;
    }
}


@media only screen and (max-width: 1280px) {
    #app-grid {
        grid-template-columns: 120px calc(100vw - 120px);

        #app-nav {
            .menu {
                span {
                    letter-spacing: 0.04rem;
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile) {
    #app-grid {
        grid-template-rows: 80px 80px auto;

        #app-nav {
            grid-column: 1 / 3;
            grid-row: 2/2;
            z-index: 998;

            .nav-items {
                flex-direction: unset;
                margin-top: unset;
                align-items: center;
                align-content: center;
                justify-content: center;
                vertical-align: middle;
                height: 100%;

                .nav-item {
                    margin-bottom: unset;
                    font-size: 14px;
                    margin-left: 15px;
                    margin-right: 15px;

                    .icon {
                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }
        }

        #app-content {
            grid-area: 3/1/3/3;
        }
    }
}

</style>
