<template>
    <div class="auth" id="login">
        <h1 class="title">Reset your password</h1>
        <form @submit.prevent="login" v-if="!success">
            <LabelInput :errors="errors" v-model="form.email" label="Email" name="email"></LabelInput>
            <LabelInput
                :errors="errors"
                label="Password"
                name="password"
                type="password"
                v-model="form.password"
            ></LabelInput>
            <LabelInput
                :errors="errors"
                label="Password Confirmation"
                name="password_confirmation"
                type="password"
                v-model="form.password_confirmation"
            ></LabelInput>
            <div>
                <button :disabled="busy" class="btn">Reset</button>
            </div>
        </form>
        <div v-else class="description">
            <div class="success">{{ success }}</div>
        </div>
        <div style="margin-top: 15px; text-align: center">
            <router-link :to="{name: 'Login'}">Back to Login</router-link>
        </div>
    </div>
</template>
<script>
import api from "../../utils/api";
import LabelInput from "../../components/Form/LabelInput";

export default {
    components: {LabelInput},
    data() {
        return {
            busy: false,
            errors: null,
            success: null,
            form: {
                token: this.$route.params.token,
                email: this.$route.query.email,
                password: '',
                password_confirmation: '',
            }
        }
    },
    mounted() {
        if (!this.form.token) {
            this.$router.replace({name: 'Login'})
        }
    },
    methods: {
        login() {
            this.busy = true;
            this.errors = null;
            api.get('../sanctum/csrf-cookie').then(() => {
                api.post('../auth/reset-password', this.form)
                    .then(() => {
                        this.success = 'Password reseted';
                    })
                    .catch(error => {
                        if (error.response.data.errors) {
                            this.errors = error.response.data.errors;
                        }
                        this.busy = false;
                    })
            })
        }
    }
}
</script>
