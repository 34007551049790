<template>
    <div id="auth">
        <div class="logo">
            <inline-svg :src="require('../../assets/logo.svg')" />
        </div>
        <div class="card">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "src/sass/variables";

#auth{
    background: $dark;
    min-height: 100vh;
    padding: 20px;

    @media (min-height: 700px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .logo{
            margin-top: -150px;
        }
    }

    .logo{
        margin-bottom: 35px;

        svg {
            width: 110px;

            path, polygon {
                fill: white;
            }
        }
    }

    .card{
        background-color: #fff;
        padding: 35px 35px;
        box-sizing: border-box;
        box-shadow: 2px 2px 12px rgb(0 0 0 / 16%);
        border-radius: 12px;
        width: 420px;
    }

    .title{
        text-align: center;
        color: #888787;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.2px;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 35px;
    }

    a{
        color: $colour;
    }

    .btn{
        background: $colour;
        color: white;
        border: unset;
        width: 100%;
    }
}
</style>
