<template>
    <div id="user" class="wrapper page">
        <h1 class="title margin-bottom-55">User</h1>
        <form @submit.prevent="submit" id="user-content" v-if="user">
            <LabelImage
                :errors="errors"
                label="Image"
                name="avatar"
                v-model="image"
            ></LabelImage>
            <LabelSelect
                :errors="errors"
                label="Type"
                name="type"
                v-model="user.type"
                :options="{User:'User', Admin: 'Admin'}"
            ></LabelSelect>
            <LabelInput
                :errors="errors"
                label="Name"
                name="name"
                v-model="user.name"
            ></LabelInput>
            <LabelInput
                :errors="errors"
                label="Email"
                name="email"
                type="email"
                v-model="user.email"
            ></LabelInput>
            <LabelInput
                :errors="errors"
                label="Password"
                name="password"
                type="password"
                v-model="user.password"
                autocomplete="off"
            ></LabelInput>
            <div class="success" v-if="success">
                {{ success }}
            </div>
            <div class="buttons">
                <button :disabled="busy" class="btn btn-primary">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
import api from "../../../utils/api";
import LabelInput from "../../../components/Form/LabelInput";
import LabelSelect from "../../../components/Form/LabelSelect";
import LabelImage from "../../../components/Form/LabelImage";

export default {
    components: {LabelImage, LabelSelect, LabelInput,},
    data() {
        return {
            busy: false,
            errors: null,
            success: null,
            user: null,
            image: null,
        }
    },
    mounted() {
        if (this.$route.params.id === 'create') {
            this.user = {
                id: null,
                name: null,
                email: null,
                password: null,
                type: 'User',
            }
        } else {
            api.get('/api/users/' + this.$route.params.id).then(response => {
                this.user = {
                    ...response.data.user,
                    password: null,
                };
            });
        }
    },
    methods: {
        submit() {
            this.busy = true;
            this.errors = null;
            let request;
            let formData = new FormData();
            Object.keys(this.user).forEach((key) => {
                if(this.user[key]){
                    formData.append(key, this.user[key]);
                }
            });
            if (this.image) {
                formData.append('image', this.image);
            }
            if (this.user.id) {
                formData.append('_method', 'PUT');
                request = api.post('/api/users/' + this.user.id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } else {
                request = api.post('/api/users', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }
            request
                .then((response) => {
                    if (!this.user.id) {
                        this.$router.replace({name: 'User', params: {id: response.data.user.id}})
                    }
                    this.user = {
                        ...response.data.user,
                        password: null,
                    };
                    this.image = null;
                    this.success = 'User saved.'
                })
                .catch(error => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;
                    }
                })
                .finally(() => {
                    this.busy = false;
                })

        }
    }
}
</script>

<style lang="scss">
#user {

}
</style>
