<template>
    <TwoColumnsBoxed id="reporting" class="fadeup">
        <template v-slot:column-left>
            <div class="box">
                <h1 class="title margin-bottom-35">Reporting</h1>
                <div class="second-nav">
                    <router-link :to="{name: 'ReportCreate'}">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.66 110.79">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <g class="cls-1">
                                            <polygon class="cls-2"
                                                     points="0 110.79 0 81.34 9.07 81.34 9.07 101.72 29.62 101.72 29.62 110.79 0 110.79"/>
                                            <polygon class="cls-2"
                                                     points="64.41 110.79 64.41 101.72 84.96 101.72 84.96 81.34 94.03 81.34 94.03 110.79 64.41 110.79"/>
                                            <polygon class="cls-2"
                                                     points="29.62 4.42 29.62 13.48 9.07 13.48 9.07 33.86 0 33.86 0 4.42 29.62 4.42"/>
                                            <path class="cls-2"
                                                  d="M24.78,41V38H62a25.28,25.28,0,0,1-5.33-15.56q0-1.25.12-2.46H15.62v74.8H76.39V47.28A25.5,25.5,0,0,1,64.77,41ZM69.25,79.79H24.78v-3H69.25Zm0-19.37H24.78v-3H69.25Z"/>
                                        </g>
                                        <path class="cls-2"
                                              d="M82.18,0A22.5,22.5,0,0,0,59.85,20,22.3,22.3,0,0,0,66,38a22.85,22.85,0,0,0,3.25,2.8A22.47,22.47,0,1,0,82.18,0ZM97.09,22.94H84V36.07H81V22.94H67.84v-3H81V6.82h3V19.94H97.09Z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="title-2">Create Report</div>
                    </router-link>
                    <router-link :to="{name: 'Reports'}" exact>
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.64 107.65">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                        <g class="cls-1">
                                            <polygon class="cls-2"
                                                     points="0 107.65 0 78.21 9.07 78.21 9.07 98.59 29.62 98.59 29.62 107.65 0 107.65"/>
                                            <polygon class="cls-2"
                                                     points="64.41 107.65 64.41 98.59 84.96 98.59 84.96 78.21 94.03 78.21 94.03 107.65 64.41 107.65"/>
                                            <polygon class="cls-2"
                                                     points="29.62 1.28 29.62 10.35 9.07 10.35 9.07 30.73 0 30.73 0 1.28 29.62 1.28"/>
                                            <path class="cls-2"
                                                  d="M73.71,34.9l-.14.1-21,17.93a2.24,2.24,0,0,1-2.74.23,2.18,2.18,0,0,1-1-1.74,2.53,2.53,0,0,1,0-.28V37.91h-24v-3h24v-18H15.62v74.8H76.39V37.15L73.87,35A.46.46,0,0,0,73.71,34.9ZM69.25,76.66H24.78v-3H69.25Zm0-19.37H24.78v-3H69.25Z"/>
                                        </g>
                                        <path class="cls-2"
                                              d="M94.86,0H52.43a.7.7,0,0,0-.63.77V49.63L65.54,37.91l3.51-3,2.57-2.19a3.1,3.1,0,0,1,4.2,0l.57.48L95.64,49.63V.77A.69.69,0,0,0,94.86,0ZM71.78,27.36l-7.12-7.12,2.12-2.12,4.59,4.58L84.22,4.23l2.46,1.71Z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="title-2">Saved Report(s)</div>
                    </router-link>
                </div>
            </div>
        </template>
        <template v-slot:column-right>
            <router-view></router-view>
        </template>
    </TwoColumnsBoxed>
</template>

<script>
    import TwoColumnsBoxed from "../components/Skeleton/TwoColumnsBoxed";
    export default {
        name: 'Reporting',
        components: {TwoColumnsBoxed},
        data() {
            return {};
        },
        mounted() {

        },
        methods: {},
        computed: {},
        watch: {},
    }
</script>

<style lang="scss">
    @import "src/sass/variables";

    #reporting {
        .second-nav {
            .icon {
                width: 30px;
                height: 30px;
            }
        }
        svg {
            height: 100%;
            width: 100%;
            .cls-1 {
                opacity: 0.6;
                polygon, path {
                    opacity: 0.6;
                }
            }

            .cls-2 {
                opacity: 1;
            }
        }
    }

    .modal-camera {
        img {
            max-width: 100%;
        }
    }
</style>
